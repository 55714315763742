import { template as template_ce0ec252e7d34882aff0409da4c4059a } from "@ember/template-compiler";
const FKLabel = template_ce0ec252e7d34882aff0409da4c4059a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
