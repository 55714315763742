import { template as template_0248ef2e093d4435bad530924de3e79d } from "@ember/template-compiler";
const FKControlMenuContainer = template_0248ef2e093d4435bad530924de3e79d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
